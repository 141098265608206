import { defineComponent, ref } from "vue";
import EmojiPicker from 'vue3-emoji-picker'
import  cognitoAuth  from '../cognito';
const images = import.meta.glob('/src/assets/*.gif', { eager: true });

interface User {
  Name: string;
  SmallPhotoUrl: string;
}

export default defineComponent({
  name: "Comment",
  props: {
    comment: {
      type: Object as () => any,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EmojiPicker,
  },
  
  emits: ["add-reply"],
  setup(props, { emit }) {
    const showReplyBox = ref(false);
    const replyText = ref("");
    const showEmojiPicker = ref(false);
    const showEmojiPickerTextBox =ref(false)
    const showGifPicker = ref(false);
    const replyDialog = ref(false);
    const emojiPicker = ref<HTMLElement | null>(null);
    const triggerButton = ref<HTMLElement | null>(null);
    const pickerPosition = ref({ top: 0, left: 0 });
    const isSubmittingCommention = ref(false);
    const selectedGifs = ref<String[]>([]);
    const gifs = Object.keys(images);
    const toggleReplyBox = () => {
      showReplyBox.value = !showReplyBox.value;
    };
    const customEmojis = [
      {
        id: "custom1",
        name: "Custom Smile",
        shortcodes: [":custom_smile:"],
        imageUrl: "../../assets/logo.gif",
      }
    ]
  const submitReply = async(comment) => {
    isSubmittingCommention.value = true; 
    const attachments = []
    const commentPayload = {
      "order_id": comment.order_id,
      "parent_id": comment.id,
      "type": "Discussion",
      "comment": replyText.value,
      "created_by": "pankaj.sharma@meltwater.com",
      "attachments": attachments        
    }
    for(let gif in selectedGifs.value) {
      const att = {
        "name": "skycontent.png",
        "type":"Memes",
        "url":selectedGifs.value[gif]
        }
        attachments.push(att);
    }
    if(attachments.length) {
      commentPayload.attachments = attachments;
    }
    const token = await getToken();
    const response = await fetch(import.meta.env.VITE_API_URL+'/discussions/'+comment.order_id,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(commentPayload),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }    
    emit('add-reply');
    replyText.value = "";
    showReplyBox.value = false;
    selectedGifs.value = [];
    isSubmittingCommention.value = false;
    return data.response;      
  };

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const extractText = (commentMessage : string) => {
    // Remove GIF marker from text
    const mentionRegex = /@\[(.+?)\]\(userId:(.+?)\)/g;
    commentMessage = commentMessage.replace(/\[GIF:([^\]]+)\]/, "").trim();
    commentMessage = commentMessage.replace(
      mentionRegex,
      '<a class="mention" data-user-id="$2" style="background-color: rgba(29, 155, 209, 0.1) !important;color:rgb(18, 100, 163) !important; border-radius:5px; padding: 3px 3px 3px 3px ">@$1</a>'
    );
    return commentMessage;
  };
    const extractGifs = (commentMessage:string) => {
      const regex = /\[GIF:([^\]]+)\]/g;
      const matches = [];
      let match;  
      // Use a loop to find all matches
      while ((match = regex.exec(commentMessage)) !== null) {
        matches.push(match[1]);
      }  
      return matches;
    };
    const toggleEmojiPicker= async() => {
      showEmojiPicker.value = !showEmojiPicker.value;
    };
    
    const onShowEmojiTextBox = (emoji) => {
      showEmojiPickerTextBox.value = !showEmojiPickerTextBox.value;
      if(showGifPicker.value) {
        showGifPicker.value = !showGifPicker.value;
        
      }   
    }
    const toggleGifPicker = () => {
      showGifPicker.value = !showGifPicker.value;
      if(showEmojiPickerTextBox.value) {
        showEmojiPickerTextBox.value = !showEmojiPickerTextBox.value;
      }
    }

    const addGif = (gif: string) => {  
      if (!selectedGifs.value.includes(gif)) {
          selectedGifs.value.push(gif);
      } 
      showGifPicker.value = !showGifPicker.value;      
    }
    
    const removeGif = (index) => {
      selectedGifs.value.splice(index, 1);
    }
    const filteredReactions = (reactions) => {
      return (reactions || []).filter(gif => gif.type === 'Memes');
    };

    const filteredReactionsEmoji = (reactions) => {
      // reactions = reactions.filter(gif => gif.type === 'Emoji');
      // const filteredReactions = (reactions || [])
      //   .reduce((acc, reaction) => {
      //     acc[reaction.content] = (acc[reaction.content] || 0) + 1;
      //     return acc;
      //   }, {});

     
      // console.log("filteredReactions =>", filteredReactions);
      // return Object.entries(filteredReactions).map(([emoji, count]) => ({
      //   emoji,
      //   count,
      // }));
      reactions = reactions.filter(gif => gif.type === 'Emoji');
        const filteredReactions = (reactions || [])
          .reduce((acc, reaction) => {
            if (!acc[reaction.content]) {
              acc[reaction.content] = { count: 0, names: '' };
            }
            acc[reaction.content].count += 1;
            acc[reaction.content].names = reaction.name;
            return acc;
          }, {});

        console.log("filteredReactions =>", filteredReactions);
        return Object.entries(filteredReactions).map(([emoji, { count, names }]) => ({
          emoji,
          count,
          names,
        }));
    };
    
    const getToken = async (): Promise<string> => {
      const currentUser = cognitoAuth.getCurrentUser();
    if (!currentUser) {
      
      await new Promise<void>((resolve, reject) => {
        cognitoAuth.authenticate('topdealsadmin', 'hh#@z9@8Mk+PzWP4', (err, result) => {
          if (err) {
            console.error('Authentication failed:', err);
            reject(err);
          } else {
            console.log('Authentication successful:', result);
            resolve();
          }
        });
      });
    }
    return new Promise((resolve, reject) => {
    cognitoAuth.getIdToken((err, token) => {
        if (err) {
          reject(err);
        } else if (token) {
          resolve(token);
        }
      });
    });
  };
    
  const onSelectEmoji = async (emoji,comment) => {
    const token = await getToken();
    const payload ={
        "discussion_id": comment.id,
        "type": "Emoji",
        "name": emoji.n[1],
        "is_reacted": 1,
        "content": emoji.i,
        "created_by": "pankaj.sharma@meltwater.com"
      } ;
    const response = await fetch(import.meta.env.VITE_API_URL+'/reactions/'+comment.order_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }
    emit('add-reply');
    showEmojiPicker.value = !showEmojiPicker.value;
  };
  
  const positionEmojiPicker = () => {
    if (emojiPicker.value && triggerButton.value) {
      const buttonRect = triggerButton.value.getBoundingClientRect();
      const pickerRect = emojiPicker.value.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      let top = buttonRect.bottom + window.scrollY;
      let left = buttonRect.left + window.scrollX;
      if (left + pickerRect.width > viewportWidth) {
        left = viewportWidth - pickerRect.width - 10; 
      }
      if (top + pickerRect.height > viewportHeight) {
        top = buttonRect.top + window.scrollY - pickerRect.height; 
      }
      pickerPosition.value = { top, left };
    }
  };
  const openThreadDialog = () => {
    replyDialog.value = !replyDialog.value;
  };
  const getOrderComments = async (orderID, comment): Promise<any> =>{    
    const token = await getToken();    
    const response = await fetch(import.meta.env.VITE_API_URL+'/discussions/'+orderID,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }  
    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }
    comment =  data.response;  
  };
  const handleAddReply = () => {
    console.log("in this ssss handleAddReply");
  };

 
  const onSelectEmojiTextBox  = (emoji) => {
    replyText.value += emoji.i;
    showEmojiPickerTextBox.value = !showEmojiPickerTextBox.value;
  }

  const onRemoveReaction = async (reaction, comment) => {
    console.log(reaction, comment);
    const token = await getToken();
    const payload ={
        "discussion_id": comment.id,
        "type": "Emoji",
        "name": reaction.names,
        "is_reacted": 0,
        "content": reaction.emoji,
        "created_by": "pankaj.sharma@meltwater.com"
      } ;
    const response = await fetch(import.meta.env.VITE_API_URL+'/reactions/'+comment.order_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }
    emit('add-reply');
    
  };
 
    return {
      showReplyBox,
      replyText,
      toggleReplyBox,
      submitReply,
      formatDate,
      extractText,
      extractGifs,
      showEmojiPicker,
      toggleEmojiPicker,
      filteredReactions,
      onSelectEmoji,
      filteredReactionsEmoji,
      emojiPicker,
      triggerButton,
      pickerPosition,
      positionEmojiPicker,
      openThreadDialog,
      replyDialog,
      handleAddReply,
      onShowEmojiTextBox,
      showEmojiPickerTextBox,
      addGif,
      removeGif,
      toggleGifPicker,
      showGifPicker,
      selectedGifs,
      isSubmittingCommention,
      gifs,
      onSelectEmojiTextBox,
      onRemoveReaction,
      customEmojis
    };
  },
});

