<script setup lang="ts">
import { Deal, TotalDeal, getCurrentDate, changeDate as updateDate, formatDate, getTableHeaders, getDealsData, getBonsulyData, getRealTimeLeaderboardData ,formatTime, getTodalDeals, hasUserLiked, getTooltipMessage, toggleLikeAction, CommentData, getUserListBySearchKeyword, Collaborator, addCollaboratorsInOrder, getOrderComments, saveComments} from './Home';
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import {VDateInput} from 'vuetify/labs/VDateInput';
import Comment from "./Comments/CommentBox.vue";
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'

const images = import.meta.glob('/src/assets/*.gif', { eager: true });
const tableHeaders = getTableHeaders();
const deals = ref<Deal[]>([]);
const totalDeals = ref<TotalDeal | null>(null);
const loading = ref(true); 
const tooltiploading = ref(true);
const dealCommentDialogFullScreen = ref(false);
const collaboratorDialog = ref(false); 
const activeButton = ref<string>('topDeals'); 
const showEmojiPicker = ref(false);
const showGifPicker = ref(false);
const selectedGifs = ref<String[]>([]); 
const showSuggestions = ref(false);
const mentionStartIndex = ref<number>();
const caretPosition = ref<{ }>({top: 0, left: 0}); 
const isFilterPanelOpen = ref(false);
const isZIndexApplied = ref(false);
const currentDate = ref(getCurrentDate());
const formattedDate = computed(() => formatDate(currentDate.value));
const gifs = Object.keys(images);
const superAreas = ['Americas', 'APAC', 'CEMEA', 'EMEA', 'Nordics', 'zCorporate'];
const segments = ['SMB - Inbound Only', 'Enterprise', 'Mid-Market General Business', 'Mid-Market Commercial'];
const roles = ['Global Admin', 'CS Big Apple', 'CA Broadway Sales','CS Gold Austin', 'CA Boston Sales', 'CA Charlotte Sales'];
const selectedCollb = ref([]); // Selected chips
const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
const selectedOrderId = ref<string>();
const collaboratorArray = ref<Collaborator[]>([]);
const users = ref<Collaborator[]>([]);

const commentMessage = ref<string>('');
const commentsArray = ref<[]>([]);
const selectedCommentOrder = ref<Deal>();
const selectedcommentOrderId = ref<string>();
const isSubmittingCommention = ref(false);
let filteredUsers = [{}];
const scrollContainer = ref<HTMLDivElement | null>(null);
const commentSkentonloader = ref(true);


const loadTopDeals = () => loadDealsData(getDealsData, 'topDeals');
const loadBonsuly = () => loadDealsData(getBonsulyData, 'bonsuly');
const loadRealTimeLeaderboard = () => loadDealsData(getRealTimeLeaderboardData,'realTimeLeaderboard');
const loadTotalDeals = () => loadTodalDealsData(getTodalDeals);
const loadDealsComment = (item: Deal) => loadDealsCommentData(item, getOrderComments);
const loadCollob = () => loadAllCollob(getUserListBySearchKeyword);

onMounted(async () => {
  console.log(import.meta.env.VITE_API_URL);  
  loadTopDeals();
    loadTotalDeals();
});


const callBothApi = () => {
  loadTopDeals();
  loadTotalDeals();
 } 


const loadDealsData = async (fetchFunction: (filters: { date: string | null; superarea: string; segment: string; role: string }) => Promise<Deal[]>, buttonName: string) => {
    activeButton.value = buttonName;
    loading.value = true; 
    await new Promise(resolve => setTimeout(resolve, 1000)); 
    deals.value = await fetchFunction({
    date: currentDate.value.toDateString(),
    superarea: filters.value.superarea,
    segment: filters.value.segment,
    role: filters.value.role,
  }); 
    loading.value = false;     
};

const loadTodalDealsData = async (fetchTotalDealFunction: (filters: { date: string | null}) => Promise<TotalDeal>) => {
    loading.value = true; 
    await new Promise(resolve => setTimeout(resolve, 1000)); 
    totalDeals.value = await fetchTotalDealFunction({
    date: currentDate.value.toDateString()
  });  
  loading.value = false;     
};

const loadDealsCommentData = async (item : Deal, fetchCommentFunction: (item : Deal) => Promise<[]>) => {
  if(commentsArray.value.length == 0) {
    commentSkentonloader.value = true;
  }
  commentsArray.value = await fetchCommentFunction(item); 
  
  commentSkentonloader.value = false;
};

const loadAllCollob = async (fetchCollobFunction: () => Promise<Collaborator[]>) => {
  collaboratorArray.value = [];  
  
    await new Promise(resolve => setTimeout(resolve, 1000)); 
    collaboratorArray.value = await fetchCollobFunction(); 
    users.value = collaboratorArray.value;    
};

const loadTooltipMessage = (item: Deal) => {
  return getTooltipMessage(item);
}


const openCollaboratorDialog =(item: Deal) => {
  loadCollob();
  selectedOrderId.value = item.sf_order_id;
  selectedCollb.value =  item.collaborators.map((collab) => {
                              return { id: collab.user_id, name: collab.name }
                            });
  collaboratorDialog.value = true;
}

const openCommentModel = (item: Deal) => {
  dealCommentDialogFullScreen.value = true;
  selectedcommentOrderId.value = item.sf_order_id;
  selectedCommentOrder.value = item;
  loadCollob();
  loadDealsComment(item);
}

const openEmojiModel = () => {
  showEmojiPicker.value = !showEmojiPicker.value;
  if(showGifPicker.value) {
    showGifPicker.value = !showGifPicker.value;
    isZIndexApplied.value = true;
  } else {
    isZIndexApplied.value = !isZIndexApplied.value;
  }   
}
const toggleGifPicker = () => {
  
  showGifPicker.value = !showGifPicker.value;
  if(showEmojiPicker.value) {
    showEmojiPicker.value = !showEmojiPicker.value;
    isZIndexApplied.value = true;
  }  else {
  isZIndexApplied.value = !isZIndexApplied.value;
  } 
}

const toggleFilterPanel = () => {
  isFilterPanelOpen.value = !isFilterPanelOpen.value;
};

const closeCommentDialog = () => {
  commentsArray.value = [];
  dealCommentDialogFullScreen.value = false; 
}

const changeDate = (direction: number) => {
    currentDate.value = updateDate(currentDate.value, direction);    
    filters.value.date = new Date (currentDate.value);
    callBothApi();
};

const setToday = () => {
    currentDate.value = getCurrentDate();
    filters.value.date = new Date (currentDate.value);
    callBothApi();
};

const filters = ref({
  date: currentDate.value,            // Date filter
  superarea: '',      // Supervisor dropdown
  segment: '',         // Segment dropdown
  role: ''             // Role dropdown
});

const clearFilters = () => {
  filters.value = {
    date: currentDate.value,
    superarea: '',
    segment: '',
    role: ''
  };
  callBothApi(); 
};

const filterCount = computed(() => {
  return Object.entries(filters.value).filter(([key, value]) => key !== 'date' && value).length;
});


const handleMouseOver = async () => {
  tooltiploading.value = true; 
  await new Promise(resolve => setTimeout(resolve, 1000));
  tooltiploading.value = false;      
}
const handleLikeToggle = async (item: Deal) => {
  try {
        await toggleLikeAction(item, "pankaj.sharma@meltwater.com");
      } catch (error) {
        console.error('Error toggling like:', error);
      }
}




const onSelectEmoji = (emoji) => {
  commentMessage.value += emoji.i;
  showEmojiPicker.value = !showEmojiPicker.value;
}
const addGif = (gif: string) => {  
  if (!selectedGifs.value.includes(gif)) {
      selectedGifs.value.push(gif);
  } 
  showGifPicker.value = !showGifPicker.value;
}
const removeGif = (index) => {
  selectedGifs.value.splice(index, 1);
}
const isUserLiked = (item: Deal) => {
  return hasUserLiked(item, "pankaj.sharma@meltwater.com");
}

const scrollToBottom = async () => {
  await nextTick(); // Wait for DOM updates  
  if (scrollContainer.value) {
    scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
  }
};

const updateCaretPosition = (textarea, cursorPosition) => {  
  const rect = textarea.getBoundingClientRect();  
  const lineHeight = 4; 
  const lines = textarea.value.slice(0, cursorPosition).split('\n');  
  const top =  -rect.x + lineHeight * (lines.length - 1);
  const left = lines[lines.length - 1].length * 7; 
  caretPosition.value =  { top, left };
}

const selectUser = (user) => {
  const beforeMention = commentMessage.value.slice(0, mentionStartIndex.value);
  const afterMention = commentMessage.value.slice(mentionStartIndex.value).split(' ')[1] || '';
  commentMessage.value = `${beforeMention}@[${user.name}](userId:${user.id})${afterMention}`;
  showSuggestions.value = false;
}

const handleInput = (event) => {  
  const value = event.target.value;
  const cursorPosition = event.target.selectionStart;
  const lastAtIndex = value.lastIndexOf('@', cursorPosition - 1);
  if (lastAtIndex !== -1) {
    const afterAtText = value.slice(lastAtIndex + 1, cursorPosition);
    filteredUsers = users.value.filter((user) =>
      user.name.toLowerCase().includes(afterAtText.toLowerCase())
    );
    if (filteredUsers.length > 0) {
      showSuggestions.value = true;
      mentionStartIndex.value = lastAtIndex;
      // Position the dropdown
      updateCaretPosition(event.target, cursorPosition);
    } else {
      showSuggestions.value = false;
    }
  } else {
    showSuggestions.value = false;
  }
}


const addCollaborator = async () => {
  collaboratorDialog.value = false;
  loading.value = true;
  await addCollaboratorsInOrder(selectedOrderId.value, selectedCollb.value);
  loadTopDeals(); 
  loadTotalDeals(); 
}
const submitComment = async (sf_order_id, commentText)  => {
  //const gifEmbed = selectedGifs.value ? ` [GIF:${selectedGifs.value}]` : "";
  isSubmittingCommention.value= true;
  const commentSaveResponse = await saveComments(commentText, sf_order_id, 0, 'Order', "pankaj.sharma@meltwater.com", selectedGifs.value);
  if (selectedCommentOrder.value) {
    loadDealsComment(selectedCommentOrder.value);
  }
  
  commentMessage.value = '';
  selectedGifs.value = [];
  isSubmittingCommention.value= false;
  scrollToBottom();
}
const handleAddReply = () => { 
  if (selectedCommentOrder.value) {
    loadDealsComment(selectedCommentOrder.value);
  }
}

watch(() => filters.value.date, (newDate) => {
  if(newDate != null) {
    currentDate.value = updateDate(newDate, 0);
  }  
});
watch(commentsArray, () => {
  scrollToBottom();
});
</script>


<template>
    <v-app>
      <v-app-bar>
        <template v-slot:prepend>
          <!-- Logo -->
          <img src="../assets/black-logo.svg" width="200" class="nav-logo" />
  
          <!-- "48 Deals" Blue Box -->
          <v-card class="blue-box" flat>
            <v-card-text class="text-center" style="font-weight: 700;">
              <div class="number">{{ totalDeals != null ? totalDeals.total_deals : '0'}}</div>
              <div class="label">Deals</div>
            </v-card-text>
          </v-card>
  
          <v-card class="blue-box" flat >
            <v-card-text class="text-center" style="font-weight: 700;">
              <div class="number"> {{ totalDeals != null ?  (formatter.format(parseFloat(totalDeals.total_deal_amount))).replace('$','') : '0' }}K</div>
              <div class="label">Total Sales</div>
            </v-card-text>
          </v-card>
        </template>
  
        <template v-slot:append>
          <v-list>
            <v-list-item subtitle="Engineer I - Engineering EMEA - Pankaj Sharma - Engineering" title="Pankaj Sharma" style="text-align: right;">
              <template v-slot:append>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-avatar color="secondary" v-bind="props" class="cursor-pointer">
                      P
                    </v-avatar>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-list-item>
          </v-list>
        </template>
      </v-app-bar>
  
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12"> 
              <v-card class="full-width-card">
                <v-card-title>
                <div class="d-flex justify-space-between align-center">
                  <span>{{ formattedDate }}</span>
                  <span class="text-overline">
                    <div class="d-flex align-center">
                    <!-- Filter Button -->
                    <v-card class="icon-box" flat>
                    <v-card-text class="text-center" style="padding:2px">
                        <v-btn @click="toggleFilterPanel" :append-icon="isFilterPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      prepend-icon="mdi-filter" color="secondary" class="filter-btn-with-badge">
                        Filter
                        <v-badge
                        v-if="filterCount > 0"
                        :content="filterCount"
                        color="red"
                        class="filter-badge" floating
                        
                    />
                        </v-btn>
                    </v-card-text>
                    </v-card>
                    
                    
                    
                    <v-card class="icon-box" flat>
                    <v-card-text class="text-center" style="padding:9px">
                        <v-icon class="cursor-pointer" @click="changeDate(-1)">mdi-chevron-left</v-icon>
                    </v-card-text>
                </v-card>
                   

                    <!-- Today Button -->
                    <v-card class="icon-box" flat>
                    <v-card-text class="text-center" style="padding:2px">
                        <v-btn variant="text" @click="setToday">Today</v-btn>
                    </v-card-text>
                </v-card>
                    

                    <!-- Right Arrow -->
                    <v-card class="icon-box" flat>
                    <v-card-text class="text-center" style="padding:9px">
                        <v-icon class="cursor-pointer" @click="changeDate(1)">mdi-chevron-right</v-icon>

                    </v-card-text>
                    </v-card>
                    </div>

                  </span>
                </div>
              </v-card-title>

              <v-card-title class="text-overline">
                <div class="text-green-darken-3 text-h3 font-weight-bold"></div>
                <div class="text-h6 text-medium-emphasis font-weight-regular">
                  
                </div>
              </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col>
                <!-- Filter Panel -->
    <v-slide-y-transition>
      <v-card v-if="isFilterPanelOpen" class="mt-3 p-4">
        
        <v-row>
            <v-col cols="12" md="12"> 
              <v-card class="full-width-card">
                <v-card-title>
                <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                    <!-- Filter Button -->
                    <v-card class="filterItembox" flat>
                    <v-card-text class="text-center" style="padding:10px">
                        <v-date-input v-model="filters.date"
                        label="Select a date"
                        prepend-icon=""
                        prepend-inner-icon="$calendar" 
                        variant="outlined" 
                    ></v-date-input>
                    </v-card-text>
                    </v-card> 
                    <v-card class="filterItembox" flat>
                    <v-card-text class="text-center" style="padding:10px">
                        <v-select
                        v-model="filters.superarea"
                        :items="superAreas"
                        label="Super Area" variant="outlined"
                        ></v-select>
                    </v-card-text>
                    </v-card>
                    <v-card class="filterItembox" flat>
                    <v-card-text class="text-center" style="padding:10px">
                        <v-select
                            v-model="filters.segment"
                            :items="segments"
                            label="Segment" variant="outlined"
                            ></v-select>
                    </v-card-text>
                    </v-card>
                    <v-card class="filterItembox" flat>
                    <v-card-text class="text-center" style="padding:10px">
                        <v-select
                        v-model="filters.role"
                        :items="roles"
                        label="Role" variant="outlined"
                        ></v-select>
                    </v-card-text>
                    </v-card>
                </div>
             </div>
                </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-4" style="text-align: left;">
                <v-col class="button-group">
                    <v-btn @click="callBothApi" color="secondary">Search</v-btn>
                    <v-btn @click="clearFilters"  flat>Clear Filters</v-btn>
                    
                </v-col>
            </v-row>
           
      </v-card>
    </v-slide-y-transition>
            </v-col>    
          </v-row>  
          
          <!-- Button Row -->
          <v-row class="mb-4" style="text-align: left;">
                <v-col class="button-group">
                    <v-btn @click="callBothApi" :class="{ 'active-button': activeButton === 'topDeals' }"  >Top Deals</v-btn>
                    <v-btn @click="loadBonsuly" :class="{ 'active-button': activeButton === 'bonusly' }"  >Bonusly</v-btn>
                    <v-btn @click="loadRealTimeLeaderboard" :class="{ 'active-button': activeButton === 'realTimeLeaderboard' }" >Real-Time Leaderboard</v-btn>
                </v-col>
            </v-row>
          
          
          <!-- Deals Table -->
          <v-data-table
                :headers="tableHeaders"
                :items="deals"
                class="elevation-1 header-center"
                :loading="loading"
            >
           
     
            <template #loading>
                    <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
                <template #item.sf_created_date="{ item }">
                    {{ formatTime(item.sf_created_date) }}
                </template>
                <template #item.sales_rep="{ item }">
                    <div style="display: flex; align-items: center;">
                        <v-avatar size="32" class="mr-2" color="secondary">
                          {{ item.sales_rep.charAt(0)}}
                          <!-- <v-img
                            alt="John"
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                        ></v-img> -->
                        </v-avatar>
                        <span>{{ item.sales_rep}}</span>
                    </div>
                </template>
                <template #item.sales_office="{ item }">
                    {{ item.sales_office }}
                </template>
                <template #item.product="{ item }">
                  <span v-html="item.product.replace(/,/g, '<br />')"></span>  
                  
                </template>
                <template #item.order_amount_usd="{ item }">
                  {{ (formatter.format(item.order_amount_usd)).replace('$','') }}  
                </template>
                <template #item.account_name="{ item }">
                    
                  {{ item.account_name }}
                </template>
                <template #item.subscription_period="{ item }">
                    {{ item.subscription_period }}
                </template>
                <template #item.competitor="{ item }">
                    {{ item.competitor }}
                </template>
                <template #item.industry="{ item }">
                    {{ item.industry }}
                </template>
                <template #item.days_closed_won="{ item }">
                    {{ item.days_closed_won }}
                </template>
                <template #item.collaborator="{ item }">
                  <button class="add-coll-button" @click="openCollaboratorDialog(item)" >
                    <!-- <i class="mdi mdi-plus icon" ></i> -->
                    <span class="text">View/Add</span>
                  </button>  
                  {{ item.collaborator }}
                </template>
                <template #item.likesComments="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <v-icon v-bind="props"  class="like-icon" small
                        :color="isUserLiked(item) ? 'green' : 'gray'"
                        @mouseover="handleMouseOver"
                        @click = "handleLikeToggle(item)"
                        >
                          mdi-thumb-up 
                        </v-icon> ({{ item.like_count }})
                      </template>
                      <div class="custom-tooltip">
                        <template v-if="tooltiploading">
                          <v-progress-circular
                            indeterminate
                            color="#fffff"
                            size="24"
                            class="spinner"
                          ></v-progress-circular>
                        </template>
                        <template v-else>
                          <span>{{ loadTooltipMessage(item) }}</span>
                        </template>
                        <div class="arrow"></div>
                      </div>
                    </v-tooltip>
                  
                  
                  <!-- <v-icon class="like-icon" small color="secondary">
                    mdi-thumb-up {{ item.likesComments }}
                  </v-icon>  -->
                  <v-icon class="comment-icons" small color="secondary" @click="openCommentModel(item)">
                  mdi-comment
                </v-icon>
                </template>
                <template #item.badges="{ item }">
                    <div style="display: flex; align-items: center;">
                      <template v-if="item.subscription_period >= 24">
                      <img src="../assets/myd_icon.png" alt="">
                      </template>
                                                
                    </div>
                </template>
            </v-data-table>
          
          <!--Chip Dialog-->
          <v-dialog v-model="collaboratorDialog" max-width="900px" height="300px" class="comment-dialog">
            <v-card height="300px" class="comment-card" style="overflow: hidden">
              <v-card-title>
                Add collaborator 
              </v-card-title>
              <v-card-text>
               <!-- Combobox -->
               <v-combobox
                v-model="selectedCollb"
                :items="collaboratorArray"
                item-title="name"
                item-value="id"
                label=""
                                                
                multiple                
              >
                <!-- Chip Template -->
                <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" :input-value="selected" closable>
                  <span class="pr-2">
                    {{ item.title }}
                  </span>                  
                </v-chip>
              </template>
              </v-combobox>
              </v-card-text> 
              <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn variant="tonal"  
                    class="text-none submit-btn"
                    color="medium-emphasis"
                    min-width="92"
                    rounded  @click="addCollaborator(item)"
                    >Add Collaborator</v-btn> 
              </v-card-actions>   
            </v-card>  
          </v-dialog> 
          
          <!--Full Screen Dialog-->
          
            <v-dialog
              v-model="dealCommentDialogFullScreen"
              transition="dialog-bottom-transition"
              fullscreen
            >
              <v-card>
                <v-toolbar style="    background-color: rgb(var(--v-theme-secondary))">
                  <span style="font-size: 25px;
    font-weight: 600;
    padding: 20px;color:white">Comments ({{ commentsArray.length }})</span> 
                  <v-spacer></v-spacer>
                  <v-btn
                    icon="mdi-close"
                    @click="closeCommentDialog()"
                  ></v-btn>
                </v-toolbar>
                <v-row>
                  <v-col>
                    <!-- Skeleton Loader -->
                    <v-skeleton-loader
                                        v-if="commentSkentonloader"
                                        class="my-4"
                                        type="card"
                                        :loading="commentSkentonloader"
                                        elevation="0"
                                      >
                                        <template #default>
                                          <div v-for="i in 3" :key="i" class="mb-3">
                                            <v-skeleton-loader
                                              class="mb-2"
                                              :loading="commentSkentonloader"
                                              :type="['avatar', 'text', 'text']"
                                            />
                                          </div>
                                        </template>
                                      </v-skeleton-loader>
                                  
                    <v-card class="new_scrollable-comments" v-if="commentsArray.length" ref="scrollContainer" >
                      <Comment
                              v-for="comment in commentsArray"
                              :key="comment.id"
                              :comment="comment"
                              :level="0" 
                              @add-reply="handleAddReply"
                            />            
                      
                    </v-card>
                    <v-card class="new_scrollable-comments" v-if="commentsArray.length==0 && !commentSkentonloader">
                      <template v-slot:title>
                        <span class="font-weight-black ma-auto">Please be the first one to add a comment !!!</span>
                      </template>
                    </v-card>  
                  </v-col>
                </v-row>
                <!-- Fixed Bottom Comment Box -->
                    <v-footer absolute app class="new_comment-box" style="    background-color: rgb(var(--v-theme-secondary))">
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12">
                            <!-- Custom Text Box Using div -->
                            <div class="new_custom-textbox">
                              <div v-if="showSuggestions" class="show-suggestions-container">
                              <div
                              
                              :style="{ left: caretPosition.left + 'px' }"
                              class="mentions-dropdown"
                            >
                              <div
                                v-for="user in filteredUsers"
                                :key="user.id"
                                class="mention-item"
                                @click="selectUser(user)"
                              >
                              <div style="display: flex; align-items: center;">
                                  <v-avatar size="32" class="mr-2" color="secondary" rounded="0" style=" border-radius: 5px !important;">
                                    {{ user.name.charAt(0)}}
                                    <!-- <v-img
                                      alt="John"
                                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                                  ></v-img> -->
                                  </v-avatar>
                                  <span>{{ user.name}}</span>
                              </div>
                              
                              <!-- <v-avatar size="30" rounded="0" style="margin-left:5px;">
                                <v-img src="https://cdn.vuetifyjs.com/images/john.jpg" style=" border-radius: 6px;"/>
                              </v-avatar> {{ user.name }} -->
                              </div>
                            </div>
                            </div>
                            <!-- Emoji Picker -->
                            <div v-if="showEmojiPicker" class="emoji-picker-containe">
                            <div  class="emoji-picker">
                              <EmojiPicker :native="true" @select="onSelectEmoji" />
                            </div>
                            </div>
                            <!-- GIF Picker -->
                            <div v-if="showGifPicker" class="gif-picker-container">
                            <div  class="gif-picker">
                              <div class="gif-picker-header">
                              <v-icon class="gif-icon">mdi-gif</v-icon>
                              <span class="gif-title">GIFs</span>
                            </div>
                              <div class="gif-grid">
                                <img
                                  v-for="gif in gifs"
                                  :key="gif"
                                  :src="gif"
                                  alt="GIF"
                                  class="gif-item"
                                  @click="addGif(gif)"
                                />
                              </div>
                            </div>
                            </div>
                              <textarea
                                v-model="commentMessage" @input="handleInput"
                                placeholder="Type your comment..."
                                class="new_comment-textarea"
                              ></textarea>
                              <!-- Display Selected GIFs -->
                              
                            </div>
                            <div class="selected-gifs" v-if="selectedGifs.length > 0">
                                <img
                                  v-for="gif in selectedGifs"
                                  :key="gif"
                                  :src="gif"
                                  alt="Selected GIF"
                                  class="gif-preview"
                                />
                                <v-btn
                                icon
                                @click="removeGif(index)"
                                class="remove-gif-button"
                                color="transparent" 
                              >
                                ✖
                              </v-btn>
                              </div>
                          </v-col>
                          <v-col cols="12" class="new_icons-row">
                            <v-btn icon class="mr-3" @click="toggleGifPicker">
                              <v-icon>mdi-sticker-emoji</v-icon>
                            </v-btn>
                            <v-btn icon class="mr-3"  @click="openEmojiModel">
                              <v-icon>mdi-emoticon-happy-outline</v-icon>
                            </v-btn>
                            <div style="float: right;">
                            <v-btn icon class="mr-3" @click ="submitComment(selectedcommentOrderId, commentMessage)" :disabled="commentMessage.trim().length == 0 && selectedGifs.length == 0" >
                             
                              <v-icon v-if="!isSubmittingCommention"  >mdi-send</v-icon>
                              <v-icon v-else>mdi-loading</v-icon>
                            </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-footer>
              </v-card>
            </v-dialog>
 

          <!-- End of Dialog-->
        </v-container>
      </v-main>
    </v-app>
  </template>
  
 
 <style scoped>
  .blue-box {
   background-color: rgb(var(--v-theme-secondary)) !important; /* Blue background */
  /* Change this to your preferred shade of blue */
    color: white; /* Text color for better contrast */
    margin: 0 8px; /* Add horizontal margin for spacing */
    padding: 16px; /* Padding for internal spacing */
    border-radius: 4px; /* Optional: Rounded corners */
    min-width: 100px; /* Optional: Minimum width for consistency */
  }
  
  .full-width-card {
    width: 100%; /* Ensure the card is 100% of its parent */
    max-width: none; /* Remove any max-width restriction */
    margin-top: 16px; /* Add some spacing above cards */
  }
  .icon-box {
    background-color: rgb(var(--v-theme-secondary)) !important; /* Blue background */
  /* Change this to your preferred shade of blue */
    color: white; /* Text color for better contrast */
    margin: 0 8px; /* Add horizontal margin for spacing */
     /* Padding for internal spacing */
    border-radius: 4px; /* Optional: Rounded corners */
    
  }

.button-group {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
}

.active-button {
    background-color: rgb(var(--v-theme-secondary)) !important; /* Blue background */
 /* Example active color */
  color: white; /* Example active text color */
}
.mt-3 {
  margin-top: 16px;
}
.p-4 {
  padding: 16px;
}
.filterItembox {
  /* Change this to your preferred shade of blue */
    margin: 0 8px; /* Add horizontal margin for spacing */
     /* Padding for internal spacing */
    border-radius: 4px;
    width:200px;
}
.filter-btn-with-badge {
  position: relative;
}

.filter-badge {
  position: absolute;
  top: 13px;
  right: 13px;
  transform: translate(50%, -50%);
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.my-header-style {
  background: #666fff;
}

::v-deep .v-table .v-table__wrapper table thead tr {
  background-color: #f5f5f5; /* White background for odd rows */
}



::v-deep .v-table .v-table__wrapper table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

/* Style for even rows */
::v-deep .v-table .v-table__wrapper table tbody tr:nth-child(even) {
  background-color: #ffffff; /* Light background for even rows */
}

::v-deep .v-data-table-header__content span {
  width:100%;
  text-align: center;
}
button:focus {
  outline: none;
}
.like-icon {
  cursor: pointer;
  margin-left: 8px; /* Space between comment text and icon */
}
.comment-icons {
  margin-left: 20px;
  cursor:pointer;
  
}
.custom-tooltip {
  background-color: rgb(var(--v-theme-secondary)) !important;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  max-width: 200px;
  text-align: center;
  min-width: 200px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px; /* Adjust to move the arrow out of the box */
  width: 0;
  height: 0;
  
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: rgb(var(--v-theme-secondary)) transparent transparent transparent;
}

::v-deep .v-overlay__content {
  background-color: rgb(var(--v-theme-secondary)) !important;
}

.comment-card {
  border-radius: 20px !important;
  position: relative;
  
}
::v-deep .v-overlay__content{
  background-color: transparent !important;
}

::v-deep .v-list-item-subtitle {
  opacity: unset;
}
.comment-tools {
  background-color: #ddd;
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  overflow: visible;
}
textarea {
  resize: none !important; /* Disable resizing */
}

.submit-btn {
  background-color: rgb(var(--v-theme-secondary)) !important;
  color: white !important;
}
.emoji-button {
  margin-left: 10px;
}

.emoji-picker {
  position: absolute;
  top: -402%;
    left: 20%;
  z-index: 9999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gif-picker {
  position: absolute;
  top: -490%;
    left: 20%;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 400px;
  height: 400px;
  width: 400px;
  overflow-y: auto;
  
}

.gif-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.gif-item {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 11px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gif-item:hover {
  border-color: #007bff;
}

.selected-gifs {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  max-width: 70px;
  height: 70px;
}

.gif-preview {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.gif-picker-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid gray;
  height:54px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.remove-gif-button {
  background: white;
  border-radius: 50%;
  padding: 0;
  width:3px;
  height: 3px;
}

.mention-textarea {
  width: 100%;
}

.mentions-dropdown {
  top: -344%;
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  width:250px;
}

.mention-item {
  padding: 8px 12px;
  cursor: pointer;
}

.mention-item:hover {
  background: #f0f0f0;
}
.preview {
  border: 1px solid #ccc;
  background: #f9f9f9;
  white-space: pre-wrap;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.comments-container {
  max-height: 400px; /* Adjust to desired height */
  overflow-y: auto; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.comments-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome/Safari/Edge */
}
.add-coll-button {
  color: rgb(var(--v-theme-secondary)) !important;;
}

.new_scrollable-comments {
  max-height: calc(100vh - 277px); /* Leave space for the footer */
  overflow-y: auto;
  padding: 16px;
  
}

.new_comment-box {
  background-color: #f9f9f9;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;
}

.new_custom-textbox {
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
}

.new_comment-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
}

.new_comment-textarea::placeholder {
  color: #ccc;
}



.new_icons-row v-btn {
  
  color: gray;
}

.timestamp {
  font-size: 0.75rem;
  color: gray;
  margin-left: 10px;
}

.emoji-picker-containe  {
    position: relative;
}

.emoji-picker-containe :hover > .emoji-picker{
    display: block;
}
.gif-picker-container {
    position: relative;
}

.gif-picker-container :hover > .gif-picker-container {
    display: block;
}


.show-suggestions-container {
    position: relative;
}

.show-suggestions-container :hover > .show-suggestions-container {
    display: block;
}
  </style>

