import * as AWS from 'aws-sdk';
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

class CognitoAuth {
  private userPool!:  CognitoUserPool;
  private options: any;
  private userSession: any;

  constructor() {
    this.userSession = null;
    console.log("this.userSession", this.userSession);
  }

  configure(config: { UserPoolId: 'eu-west-1_8o9MpkLll', ClientId: '62mp17je04enuk0t53ud99a0qq', IdentityPoolId: 'eu-west-1_8o9MpkLll', region: 'eu-west-1' }) {
    if (typeof config !== "object" || Array.isArray(config)) {
      throw new Error("[CognitoAuth error] valid option object required");
    }
    
    this.userPool = new CognitoUserPool({
      UserPoolId: config.UserPoolId,
      ClientId: config.ClientId,
    });
    // Config.region = process.env.VUE_APP_AWS_REGION;
    // Config.credentials = new AWS.CognitoIdentityCredentials({
    //   IdentityPoolId: config.IdentityPoolId,
    // });
     // Set the AWS region
     AWS.config.update({ region: config.region }); // Correct way to set region in AWS SDK v2

     // Set credentials
     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
       IdentityPoolId: config.IdentityPoolId,
     });
    this.options = config;

    console.log("this.options ===>"+JSON.stringify(this.userPool), JSON.stringify(this.options));
  }

  authenticate(username: string, pass: string, cb: (err: any, result?: any) => void) {
    const authenticationData = { Username: username, Password: pass };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    console.log("authenticationData =", authenticationData, cognitoUser);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const logins: { [key: string]: string } = {}; // Define logins as an object
        logins[`cognito-idp.${this.options.region}.amazonaws.com/${this.options.UserPoolId}`] = result.getIdToken().getJwtToken();
        console.log("logins =====", logins);
        console.log("this.options.IdentityPoolId, =====", this.options.IdentityPoolId,);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.options.IdentityPoolId,
          Logins: logins,
        });
        cb(null, result);
      },
      onFailure: (err) => {console.log("err inauth =", err);
        cb(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log("New Password Is Required", JSON.stringify(userAttributes), JSON.stringify(requiredAttributes));
        cb(null, { message: "New Password Is Required", userAttributes: userAttributes });
      },
    });
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  logout() {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
  }

  async getIdToken(cb: (err: any, token?: string | null) => void) {
    const currentUser = this.getCurrentUser();
    if (!currentUser) {
      return cb(null, null);
    }

    currentUser.getSession((err: any, session: any) => {
      if (err) {
        return cb(err);
      }
      if (session.isValid()) {
        console.log("session", session);
        const expirationTime = session.getIdToken().getExpiration() * 1000;
        const currentTime = new Date().getTime();
        if (expirationTime > currentTime) {
          console.log("token is active");
          return cb(null, session.getIdToken().getJwtToken());
        } else {
          console.log("token is expired");
          if (AWS.config.credentials && (AWS.config.credentials as AWS.Credentials).needsRefresh()) {
            currentUser.refreshSession(session.refreshToken, (err, session1) => {
              if (err) {
                return cb(err);
              } else {
                return cb(null, session1.getIdToken().getJwtToken());
              }
            });
          }
        }
      }
    });
  }

  isAuthenticated(cb: (err: any, isAuthenticated: boolean) => void) {
    const cognitoUser = this.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          return cb(err, false);
        }
        console.log("session", session);
        return cb(null, true);
      });
    } else {
      cb(null, false);
    }
  }
}

// Create an instance of CognitoAuth
const cognitoAuth = new CognitoAuth();

// Configure the cognitoAuth instance with the provided config
cognitoAuth.configure({ UserPoolId: 'eu-west-1_8o9MpkLll', ClientId: '62mp17je04enuk0t53ud99a0qq', IdentityPoolId: 'eu-west-1_8o9MpkLll', region: 'eu-west-1' });

// Export the configured instance


export default cognitoAuth;