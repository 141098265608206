<script lang="ts" src="./commentbox.ts">
</script>

<template>
  <div class="message-div" style="position:relative">
    <v-row>
        <!-- User Avatar -->
        <v-col cols="auto">
          
              <v-avatar size="50" class="mr-2" color="secondary" rounded="0" style=" border-radius: 5px !important;font-size:22px">
                {{ comment.User.name.charAt(0)}}
                <!-- <v-img
                  alt="John"
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
              ></v-img> -->
              </v-avatar>
          
          <!-- <v-avatar size="50" rounded="0">
            <v-img src="../../assets/iconuser.png" style="border-radius: 14px;"/>
          </v-avatar> -->
        </v-col>
        <!-- Comment Details -->
        <v-col>
          <!-- Comment Header -->
          <div>
            <span style="font-weight: 600; color: black;font-size: 22px;">{{ comment.User.name }}</span>
            <span class="text-gray-500" style="font-size: 13px; margin-left: 10px;">{{ formatDate(comment.created_at) }}</span>
          </div>
          <!-- Comment Message -->
          <p class="mt-2" style="white-space: pre-wrap;font-weight: 400; color: black;font-size: 22px;" v-html=" extractText(comment.comment)"></p>
          <div v-if="comment.Reactions" class="comment-gif">
                    <img
                      v-for="(gif, index) in filteredReactions(comment.Reactions)"
                      :key="index"
                      :src="gif.url"
                      alt="GIF"
                      class="gif-image" 
                    />
          </div>
          <div style="margin-top: 20px;">
            <div v-for="reaction in filteredReactionsEmoji(comment.Reactions)" :key="reaction.emoji" style="padding-left: 10px;
              margin-top: 20px;
              margin-left:20px;
              display: inline-block;
              border: 1px solid blue;
              border-radius: 14px;
              padding-right: 10px;">
              <span style="font-size: 22px;">{{ reaction.emoji }}</span> <span style="font-size: 22px;">{{ reaction.count }}</span>
          </div>
          <div style="float: right; margin-right: 50px;">
          <v-btn icon style="margin-left: 20px;" @click="toggleEmojiPicker" >
            <v-icon>mdi-emoticon-happy-outline</v-icon>
          </v-btn>
          <div v-if="showEmojiPicker" class="emoji-picker" >
              <EmojiPicker 
              @select="emoji => onSelectEmoji(emoji, comment)" ref="emojiPicker" :style="{ top: pickerPosition.top + 'px', left: pickerPosition.left + 'px' }"/>
            </div>
          </div>
          </div>
          <div v-if="comment.children" style="display: inline-block;margin-left:20px;margin-top:20px;margin-bottom:20px;font-size:22px">
            <span style="color:rgb(18, 100, 163); cursor: pointer;" @click="openThreadDialog"  v-if="comment.children.length > 0 && level==0">
              <v-avatar size="30" class="mr-2" color="secondary" rounded="0" style=" border-radius: 5px !important; font-size:15px" v-for="child in comment.children.slice(0, 5)" :key="child.id">
                {{ comment.User.name.charAt(0)}}
                <!-- <v-img
                  alt="John"
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
              ></v-img> -->
              </v-avatar>

              <!-- <v-avatar size="30" rounded="0" v-for="child in comment.children.slice(0, 5)" :key="child.id" style="margin-left:5px;">
                <v-img src="../assets/iconuser.png" style=" border-radius: 6px;"/>
              </v-avatar> -->
              {{comment.children.length}} replies</span>
            <span style="color:rgb(18, 100, 163); cursor: pointer;" @click="openThreadDialog"  v-if="comment.children.length == 0 && level== 0">reply</span>
          </div>
        </v-col>  
    </v-row>
    <v-dialog fullscreen
      v-model="replyDialog"
      class="reply-dialog" style="width: 800px !important;height: auto;margin-right: 0px;margin-top: 0px;margin-bottom: 0px;"
    >
      <v-card  style="float:right">
        <v-card-title><h3>Thread</h3></v-card-title> 
        <v-card-text>
          <v-row>
        <!-- User Avatar -->
        <v-col cols="auto">
          <v-avatar size="50" class="mr-2" color="secondary" rounded="0" style=" border-radius: 5px !important;font-size:22px">
                {{ comment.User.name.charAt(0)}}
                <!-- <v-img
                  alt="John"
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
              ></v-img> -->
              </v-avatar>
          <!-- <v-avatar size="50" rounded="0">
            <v-img src="../assets/iconuser.png" style="border-radius: 14px;"/>
          </v-avatar> -->
        </v-col>
        <!-- Comment Details -->
        <v-col>
          <!-- Comment Header -->
          <div>
            <span style="font-weight: 600; color: black;font-size: 22px;">{{ comment.User.name }}</span>
            <span class="text-gray-500" style="font-size: 13px; margin-left: 10px;">{{ formatDate(comment.created_at) }}</span>
          </div>
          <!-- Comment Message -->
          <p class="mt-2" style="white-space: pre-wrap;font-weight: 400; color: black;font-size: 22px;" v-html=" extractText(comment.comment)"></p>
          <div v-if="comment.Reactions" class="comment-gif">
                    <img
                      v-for="(gif, index) in filteredReactions(comment.Reactions)"
                      :key="index"
                      :src="gif.url"
                      alt="GIF"
                      class="gif-image" 
                    />
          </div>
          <div style="margin-top: 20px;">
            <div v-for="reaction in filteredReactionsEmoji(comment.Reactions)" :key="reaction.emoji" style="padding-left: 10px;
              margin-top: 20px;
              margin-left:20px;
              display: inline-block;
              border: 1px solid blue;
              border-radius: 14px;
              padding-right: 10px;">
              <span style="font-size: 22px;">{{ reaction.emoji }}</span> <span style="font-size: 22px;">{{ reaction.count }}</span>
          </div>
          <div style="float: right; margin-right: 50px;">
          <v-btn icon style="margin-left: 20px;" @click="toggleEmojiPicker" >
            <v-icon>mdi-emoticon-happy-outline</v-icon>
          </v-btn>
          <div v-if="showEmojiPicker" class="emoji-picker" >
              <EmojiPicker @select="emoji => onSelectEmoji(emoji, comment)" ref="emojiPicker" :style="{ top: pickerPosition.top + 'px', left: pickerPosition.left + 'px' }"/>
            </div>
          </div>
          </div> 
               
        </v-col>
         
    </v-row> 
          <div style="margin-top:  10px;margin-bottom: 10px;">
          {{ comment.children.length  }} replies<hr /> 
          </div>
          <template v-for="reply in comment.children" :key="reply.id">
            <Comment
              :comment="reply"
              :level="level + 1"
              @add-reply="$emit('add-reply')"
            />
          </template>
          
            
          
         </v-card-text>  
        <v-card-actions>
          <!-- Fixed Bottom Comment Box -->
          
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" style="    position: relative;">
                            <!-- Emoji Picker -->
                            <div v-if="showEmojiPickerTextBox" class="emoji-picker_new">
                              <EmojiPicker :native="true" @select="onSelectEmojiTextBox" />
                            </div>
                            
                            <!-- Custom Text Box Using div -->
                            <div class="new_custom-textbox">
                              <div v-if="showGifPicker" class="gif-picker">
                              <div class="gif-picker-header">
                              <v-icon class="gif-icon">mdi-gif</v-icon>
                              <span class="gif-title">GIFs</span>
                            </div>
                              <div class="gif-grid">
                                <img
                                  v-for="gif in gifs"
                                  :key="gif"
                                  :src="gif"
                                  alt="GIF"
                                  class="gif-item"
                                  @click="addGif(gif)"
                                />
                              </div>
                            </div>  
                            
                              <textarea
                                v-model="replyText"
                                placeholder="Reply..."
                                class="new_comment-textarea"
                              ></textarea>
                              <!-- Display Selected GIFs -->
                              
                            </div>
                            <div class="selected-gifs" v-if="selectedGifs.length > 0">
                                <img
                                  v-for="gif in selectedGifs"
                                  :key="gif"
                                  :src="gif"
                                  alt="Selected GIF"
                                  class="gif-preview"
                                />
                                <v-btn
                                icon
                                @click="removeGif(index)"
                                class="remove-gif-button"
                                color="transparent" 
                              >
                                ✖
                              </v-btn>
                              </div>
                          </v-col>
                          <v-col cols="12" class="new_icons-row">
                            <v-btn icon class="mr-3" @click="toggleGifPicker">
                              <v-icon>mdi-sticker-emoji</v-icon>
                            </v-btn>
                            <v-btn icon class="mr-3"  @click="onShowEmojiTextBox">
                              <v-icon>mdi-emoticon-happy-outline</v-icon>
                            </v-btn>
                            <div style="float: right;">
                            <v-btn icon class="mr-3" @click ="submitReply(comment)" :disabled="replyText.trim().length == 0 && selectedGifs.length == 0" >
                             <v-icon v-if="!isSubmittingCommention"  >mdi-send</v-icon>
                             <v-icon v-else>mdi-loading</v-icon>
                            </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                   
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </div>  
</template>
  <style scoped>
  .mt-3 {
  margin-top: 16px; /* Adjust as needed */
}

.text-body-1 {
  font-size: 20px !important;
  font-weight: 600;
}

.text-body-2 {
  font-size: 18px !important;
}

.text-caption {
  font-size: 13px !important;
  color: #6c757d; /* Adjust text color */
}

::v-deep .v-card--variant-elevated {
  box-shadow: none;
}
.comment-gif {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.gif-image {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
}
.mention {
  color: white; /* Slack-style blue */
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(var(--v-theme-secondary)) !important;
}
.mention:hover {
  text-decoration: underline;
  
}
.emoji-picker {
  position: absolute;
  top:20px;
  right:20px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.emoji-picker_new {
  position: absolute;
  top:-350px;;
  right:20px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.emoji-button {
  margin-left: 10px;
}
button:focus {
  outline: none;
}

.message-div {
  font-family: Arial, Helvetica, sans-serif !important;
}
.message-div:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  cursor: pointer;
}
::v-deep .v3-emoji-picker {
  width:500px;
  height: 500px;
}
.reply-dialog {
  width:300px;
  height:800px
}
.new_comment-box {
  background-color: #f9f9f9;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;
}

.new_custom-textbox {
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
}

.new_comment-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
}

.new_comment-textarea::placeholder {
  color: #ccc;
}



.new_icons-row v-btn {
  
  color: gray;
}

.gif-picker {
  position: absolute;
  top: -267px;
    left: 138px;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 400px;
  height: 400px;
  width: 400px;
  overflow-y: auto;
  
}

.gif-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.gif-item {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 11px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gif-item:hover {
  border-color: #007bff;
}

.selected-gifs {
  display: flex;
  gap: 10px;
  margin-top:  25px;
  max-width: 70px;
  height: 70px;
}

.gif-preview {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.gif-picker-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid gray;
  height:54px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.remove-gif-button {
  background: white;
  border-radius: 50%;
  padding: 0;
  width:3px;
  height: 3px;
}
  </style>