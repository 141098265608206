
import  cognitoAuth  from './cognito';
// Import Amplify and the Auth module
import { computed, reactive } from 'vue';

export interface Deal {
  sf_created_date: string;
  sales_rep: string;
  sales_office: string;
  product: string;
  order_amount_usd: number;
  account_name: string;
  subscription_period: number;
  competitor: string;
  industry: string;
  days_closed_won: number;
  collaborator: string;
  likesComments: string;
  superArea: string;
  sf_order_id: string;
  isLiked: boolean;
  like_count: number;
  liked_by_users: userLikeData[]
}

export interface TotalDeal {
  date: string;
  total_deals: string;
  total_deal_amount: string;
}

interface userLikeData {
  user_id: string;
  name: string;
  email: string;
}

export interface CurrentUser {
  id: number;
  email: string;
}
interface User {
  Name: string;
  SmallPhotoUrl: string;
  sf_user_id ?: string

}

export interface CommentData {
  id: number;
  InsertedBy?: User;
  CreatedDate?:Date;
  CommentBody?: string;
  likeCount?: number;
  replies?: CommentData[];
  is_active: boolean;
  owner_id: string;
  parent_id: number
  type: string;
  comment: string
  updatedAt: string;
  createdAt: string;
  created_by: string;
  modified_by:string;
  order_id:string;
  User ?: User;
  like_count?: number;
  liked_by_users? : [];
  children?:  [];


}

export interface Collaborator {
  id: number;
  name: string
}
export function getCurrentDate(): Date {
  return new Date();
}

export function changeDate(currentDate: Date, direction: number): Date {
  const newDate = new Date(currentDate);
  newDate.setDate(newDate.getDate() + direction);
  
  return newDate;
}

export function formatDate(date: Date): string {
  return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
  });
}

export function formatDatePayload(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// dealsTableData.ts
export function getTableHeaders() {
  return [
    { title: 'Time', value: 'sf_created_date' , sortable: true},
    { title: 'Sales Rep', value: 'sales_rep' },
    { title: 'Office', value: 'sales_office' },
    { title: 'Product', value: 'product' },
    { title: 'USD', value: 'order_amount_usd' },
    { title: 'Account Name', value: 'account_name' },
    { title: 'Term', value: 'subscription_period' },
    { title: 'Competitor Switch', value: 'competitor' },
    { title: 'Industry', value: 'industry' },
    { title: 'Days Qualified', value: 'days_closed_won' },
    { title: 'Collaborator', value: 'collaborator' },
    { title: 'Likes/Comments', value: 'likesComments' },
    { title: 'Badges', value: 'badges' },
  ];
}


 export const getDummyDealsData = async (): Promise<Deal[]> => {
   return new Promise((resolve) => {
       setTimeout(() => {
           resolve([
               //{ sf_created_date: '10:00 AM', sales_rep: 'Alice', sales_office: 'NY', product: 'Product A', order_amount_usd: 500, account_name: 'Account 1', subscription_period: 32 , competitor: 'Yes', industry: 'Tech', days_closed_won: 30, collaborator: 'Bob', likesComments: 'Good deal' , superArea:'Americas', sf_order_id: '1234', isLiked: false, like_count: 10, liked_by_users: [{}]},
               // Add more mock deals
           ]);
       }, 500);
   });
 };

export const getBonsulyData = async (): Promise<Deal[]> => {
  return new Promise((resolve) => {
      setTimeout(() => {
          resolve([
              // Mock data for Bonsuly
          ]);
      }, 500);
  });
};

export const getRealTimeLeaderboardData = async (): Promise<Deal[]> => {
  return new Promise((resolve) => {
      setTimeout(() => {
          resolve([
              // Mock data for Real-Time Leaderboard
          ]);
      }, 500);
  });
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};



export function hasUserLiked(item: Deal, email: string): boolean {
  return item.liked_by_users.some((user) => user.email === email);
}

/**
 * Generate the tooltip message for likes
 */
export function getTooltipMessage(item: Deal): string {
  const likedUsers = item.liked_by_users.map((user) => user.name);
  if (likedUsers.length === 0) {
    return 'Be the first to like this!';
  } else {
    return `${likedUsers.join(', ')} liked this.`;
  }
}

// Helper function to authenticate the user if they aren't logged in
async function getToken(): Promise<string> {
  const currentUser = cognitoAuth.getCurrentUser();
    if (!currentUser) {
      // Wrap the authenticate method in a Promise to use it with await
      await new Promise<void>((resolve, reject) => {
        cognitoAuth.authenticate('topdealsadmin', 'hh#@z9@8Mk+PzWP4', (err, result) => {
          if (err) {
            console.error('Authentication failed:', err);
            reject(err);
          } else {
            console.log('Authentication successful:', result);
            resolve();
          }
        });
      });
    }
  return new Promise((resolve, reject) => {
    cognitoAuth.getIdToken((err, token) => {
      if (err) {
        reject(err);
      } else if (token) {
        resolve(token);
      }
    });
  });
}



// Main function to get deals data, ensuring user is logged in first
export const getDealsData = async (filters: { date: string | null; superarea: string; segment: string; role: string }): Promise<Deal[]> => {
  try {
    // Ensure user is authenticated
    let selectedDate = '';
    
    // Get the token
    const token = await getToken();

    // Format the date if available
    if (filters.date != null) {
      selectedDate = formatDatePayload(new Date(filters.date));
    }
    
    // Make the API request
    const response = await fetch(import.meta.env.VITE_API_URL+'/sales/topdeals', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      },
      body: JSON.stringify({
        date: selectedDate,
        super_area: filters.superarea,
        customer_segment: filters.segment,
        role: filters.role
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return Array.isArray(data.response) ? data.response : [];
  } catch (err) {
    console.error('API Error:', err);
    return [];
  }
};


export const getTodalDeals = async (filters: { date: string | null }): Promise<TotalDeal> => {
  try {
    // Ensure user is authenticated
    let selectedDate = '';
    
    // Get the token
    const token = await getToken();

    // Format the date if available
    if (filters.date != null) {
      selectedDate = formatDatePayload(new Date(filters.date));
    }

    // Make the API request
    const response = await fetch(import.meta.env.VITE_API_URL+'/sales/totaldeals', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      },
      body: JSON.stringify({ date: selectedDate }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }

    
    return data.response as TotalDeal;
  } catch (err) {
    console.error('API Error:', err);
    throw err;
  }
};




// Define your function in this separate TypeScript file
export async function toggleLikeAction(item: Deal, email:string): Promise<any> {
  // Get the token
  const token = await getToken();
  // Simulating an API call with a Promise
  const isCurrentlyLiked = hasUserLiked(item, email);
  // Make the API request
  const response = await fetch(import.meta.env.VITE_API_URL+'/reactions/'+item.sf_order_id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Attach token here
    },
    body: JSON.stringify({
        "order_id": item.sf_order_id,
        "type": "Like",
        "name": "Like",
        "is_reacted": isCurrentlyLiked ? 0 : 1,
        "created_by": "pankaj.sharma@meltwater.com"
    }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid data structure');
  }

  
  if (isCurrentlyLiked) {
    item.liked_by_users = item.liked_by_users.filter(
      (user) => user.email !== email
    );
    item.like_count -= 1;
  } else {
    item.liked_by_users.push({
      user_id: "0055f00000CF4KAAA1",
      name: "Pankaj Sharma",
      email: "pankaj.sharma@meltwater.com"
    });
    item.like_count += 1;
  }

  return reactive(item);
  //return data.response;
};


export async function getOrderComments(item: Deal): Promise<any> {
  
  const token = await getToken();
  
  const response = await fetch(import.meta.env.VITE_API_URL+'/discussions/'+item.sf_order_id,{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid data structure');
  }
  
  
  return data.response;

};
export async function saveComments(commentText : string, sf_order_id: string, parent_id: number, type: string, created_by: string, selectedGifs : String[]): Promise<any> {
  const attachments = []
  
  const commentPayload = {
    "order_id": sf_order_id,
    "parent_id": parent_id,
    "type": type,
    "comment": commentText,
    "created_by": created_by,
    "attachments": attachments
  }

  for(let gif in selectedGifs) {
    
    const att = {
      "name": "skycontent.png",
      "type":"Memes",
      "url":selectedGifs[gif]
     }
     attachments.push(att);
  }
  if(attachments.length) {
    commentPayload.attachments = attachments;
  }

  
  const token = await getToken();
  
  const response = await fetch(import.meta.env.VITE_API_URL+'/discussions/'+sf_order_id,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(commentPayload),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid data structure');
  }
  
  
  return data.response;
};


export async function addCollaboratorsInOrder(orderId, collaborators): Promise<UserList> {
  try {
    
    // Get the token
    const token = await getToken();

    collaborators = collaborators.map((item) => {
      return {order_id : orderId, user_id: item.id, created_by: 'subodh.yadav@meltwater.com' }
    });
    // Make the API request
    const response = await fetch(import.meta.env.VITE_API_URL+'/collaborators/'+orderId , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      },
      body: JSON.stringify(collaborators)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }
    //alert(JSON.stringify(data.response));
    return data.response;
  } catch (err) {
    console.error('API Error:', err);
    throw err;
  }
};

export async function getUserListBySearchKeyword(): Promise<UserList> {
  let searchKeyword = '';
  try {
    
    // Get the token
    const token = await getToken();

    // Make the API request
    const response = await fetch(import.meta.env.VITE_API_URL+'/user?name='+searchKeyword , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach token here
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data structure');
    }
    return data.response.map((item) => {
      return { id: item.sf_user_id, name: item.name }
    })
    //return data.response as UserList;
  } catch (err) {
    console.error('API Error:', err);
    throw err;
  }
};